import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button } from '@nextui-org/react';
import { COMPANY_PHONE, COMPANY_EMAIL } from '../../../constants/contact.js';

PrivacyPolicyModal.propTypes = {
    disclosure: PropTypes.object.isRequired,
};

const paragraphs = [
    {
        text: <p>PATATAG ha desarrollado esta Política de Privacidad para describir el modo en que se recopila la información y las maneras en que la información y los datos personales son tratados y compartidos. Asimismo, se señalan las opciones que Usted tendrá respecto a dichos usos y divulgaciones. Al utilizar el sitio, usted está aceptando los términos y condiciones descriptos en <a href='https://patatag.com/' target='_blank' rel='noopener noreferrer'>https://patatag.com/</a> y prácticas descriptas en esta Política de Privacidad. SI USTED NO ESTÁ DE ACUERDO CON LOS TÉRMINOS Y CONDICIONES Y/O ESTA POLÍTICA DE PRIVACIDAD, POR FAVOR NO UTILICE ESTE SITIO WEB.</p>,
    },
    {
        text: <p>En caso de ser necesario, PATATAG podrá complementar la presente Política de Privacidad con información y/o términos y condiciones específicos con relación a un determinado Servicio.</p>,
    },
    {
        text: <p>Queremos proporcionar una experiencia segura para nuestros usuarios. Pondremos nuestros esfuerzos diligentemente para que vuestra información esté protegida. En tal sentido, PATATAG cumplirá con las medidas de seguridad previstas legalmente para ayudar a proteger su información personal frente al acceso no autorizado y la divulgación no permitida. Sin embargo, cabe destacar que ningún sistema puede ser completamente seguro y que no respondemos por terceros ajenos a PATATAG. Por lo tanto, si bien tomamos medidas razonables para proteger su información, no garantizamos, y usted no debe esperar, que su información personal, o la de información de su mascota, registros u otras comunicaciones gocen de seguridad inexpugnable o libre de filtraciones. Usted deberá tener cuidado con la forma en que maneja y divulga su información personal.</p>,
    },
    {
        text: <p>Los servicios provistos por PATATAG están dirigidos únicamente a quienes tengan capacidad legal para contratar y no se encuentren inhibidos legalmente o de algún modo vedados de ejercer actos jurídicos, derechos y/u obligaciones. Habida cuenta de ello, los menores de 18 años no tienen permitido el ingreso al Sitio y/o los servicios ni el suministro de ningún dato personal u otro tipo de información.</p>,
    },
    {
        text: <p>Podremos recopilar y almacenar cualquier información personal que usted ingrese en el sitio, nos proporcione o cargue en los enlaces que llevan a los códigos QR. Esto incluye información de identificación, como su nombre y apellido, documento de identidad, imagen personal, domicilio, dirección de correo electrónico y número de teléfono.</p>,
    },
    {
        text: <p>Utilizamos su información personal para operar el sitio, ponernos en contacto con usted a través de notificaciones y posibles alertas de correo electrónico, a los fines de efectuar, ejecutar, investigar, recoger y contabilizar las transacciones relacionadas con el sitio, así como también, estipular las ofertas de nuestros servicios y contenidos, proporcionar información a los usuarios que escaneen los códigos QR.</p>,
    },
    {
        text: <p>Cuando usted accede al sitio, podemos activar una &quot;cookie&quot;. Una cookie es un pequeño archivo de texto mediante el cual se puede identificar en forma exclusiva un navegador de internet y que suele ser instalado, enviado o escrito en el ordenador de los usuarios de internet, cuando los mismos ingresan a determinados sitios Web, a efectos de obtener automáticamente información acerca de la conducta de los usuarios cuando navegan por dichos sitios.</p>,
    },
    {
        text: <p>En este sentido, podemos asignar al ordenador una o más cookies, para facilitar el acceso a nuestro sitio y para personalizar su experiencia en línea. A través del uso de esta función, también automáticamente recopilaremos información acerca de su actividad en línea en nuestro sitio, como ser las páginas Web que usted visita, la hora y la fecha de su visita, los vínculos en los que hace clic y las búsquedas que usted realiza en nuestro sitio. La mayoría de los navegadores aceptan automáticamente las cookies, pero usted puede modificar la configuración del explorador para rechazar y desactivar esta función. Al hacer esto, sin embargo, le informamos que factiblemente afectará el rendimiento en línea y ello podrá producir un impacto negativo en su experiencia en nuestro sitio.</p>,
    },
    {
        text: <p>Podremos recoger información sobre todos nuestros usuarios incluyendo, pero no limitado a; dirección IP de su equipo, geolocalización, tipo de navegador y el idioma seleccionado, tiempos de acceso, sistema operativo utilizado, información respecto a sus visitas frecuentes, rastreo del movimiento de usuario en el sitio, y para el supuesto en el que se accediere a nuestro sitio desde otro sitio Web, la dirección de este último. Esta recolección de datos es necesaria para el buen uso y funcionamiento de PATATAG. PATATAG puede almacenar dicha información por sí misma o dicha información puede ser incluida en bases de datos propiedad y mantenidas por PATATAG o sus proveedores de servicios.</p>,
    },
    {
        text: <p>Utilizamos esta información para analizar tendencias y estadísticas, a efectos de contar con mayores elementos para la mejora de nuestros servicios, evitar y prevenir cualquier tipo de delito, diagnosticar problemas con el servidor y en definitiva para la comprobación de la eficacia del sitio. En otras palabras, PATATAG puede utilizar dicha información y agruparla con otra información para mejorar la plataforma, con fines de facturación o para rastrear, por ejemplo, el número total de usuarios, el número de visitantes de cada página o interacciones dentro de la plataforma y los nombres de dominio de los proveedores de servicios de Internet de sus visitantes. PATATAG puede supervisar el uso de la plataforma y los datos de uso relacionados con dicho uso en conjunto y de manera anónima, incluyendo compilar información estadística y el rendimiento relacionado con la disposición y el funcionamiento de la plataforma. Por ejemplo, esta información sirve a PATATAG para: desarrollo de indicadores claves de desempeño, repaso de las marcas y tendencias de categorías de servicios, recomendaciones de contenido de servicios y pronóstico de desempeño. Usted acepta que PATATAG pueda utilizar y publicar estadísticas agregadas derivadas del contenido del usuario, sin exponer datos reales que sean específicos e identificables para el cliente.</p>,
    },
    {
        text: <p>PATATAG utilizará el mismo nivel de cuidado que utiliza para proteger su propia información confidencial, y tomará las medidas de seguridad administrativas, técnicas y físicas comercialmente razonables para prevenir el acceso no autorizado, uso o divulgación de cualquier Contenido del usuario.</p>,
    },
    {
        text: <p>Usted también reconoce que, mediante la emisión de identificadores de usuarios y contraseñas, controla, define y administra el acceso al contenido y la información relacionada que será utilizada por cada usuario dentro de PATATAG y también para cada mascota.</p>,
    },
    {
        text: <p>Usted es responsable del cumplimiento de todas las leyes y reglamentos de privacidad nacionales e internacionales que puedan ser aplicables al uso por parte del usuario de servicios dentro de PATATAG y el contenido del sitio, así como de la obtención de todos los consentimientos previos necesarios para la recolección, almacenamiento y uso de cualquier Contenido de usuario dentro de PATATAG.</p>,
    },
    {
        text: <p>La información personal que usted proporcione deberá ser exacta y completa, y todos los detalles del registro al sitio –en su caso- deberán contener su nombre real, dirección y cualquier otro dato que se solicite. En caso de que PATATAG descubra o sea informada de que Ud. ha falseado alguno de los datos brindados al registrarse o uno de ellos no es cierto, PATATAG podrá dar de baja su perfil.</p>,
    },
    {
        text: <p>Podremos ofrecer enlaces a otros sitios Web que consideramos eventualmente pudieran ofrecer información útil para usted. No obstante ello, es menester que Ud. acepte que no somos ni seremos responsables de los contenidos ni las políticas de privacidad de tales sitios ni de la forma de tratamiento de los datos personales de dichos sitios o terceros relacionados a los mismos. Le recomendamos que analice las políticas de privacidad de los sitios a los que accede y se interiorice respecto a sus prácticas de recolección de datos.</p>,
    },
    {
        text: <p>Podremos proporcionar su información personal -o puede usted facilitar la información directamente- a los proveedores de PATATAG y/o a nuestros partners y/o a todos aquellos que se encargan de desarrollar las aplicaciones de los servicios vinculados al sitio (por ejemplo, servidores de alojamiento de información).</p>,
    },
    {
        text: <p>PATATAG puede almacenar y divulgar información personal  y la información de su mascota-según lo permita o requiera la ley aplicable vigente- incluyendo, pero no limitado a; hacer declaraciones que sean necesarias o convenientes para: (a) cumplir con requerimientos legales o judiciales -de cumplimiento de un proceso legal-; y proteger (b) los derechos, la seguridad o la propiedad de PATATAG -incluyendo, pero no limitado a casa matriz, filiales o subsidiarias, patrocinadores, usuarios o público en general-.</p>,
    },
    {
        text: <p>En caso de que la cuenta o los servicios sean cerrados y/o finalizados, podremos retener cierta información y datos personales asociados a su cuenta para fines analíticos, evitar y prevenir fraudes, cobrar tarifas debidas, hacer cumplir nuestros Términos y Condiciones, tomar las acciones que consideremos necesarias para proteger la integridad de nuestros usuarios, o en general realizar cualquier otra acción permitida por la ley. Del mismo modo, si cierta información ya hubiera sido proporcionada a terceros como se describe más arriba en esta Política de Privacidad, la retención de dicha información estará sujeta a los términos de dichas políticas y al marco normativo aplicable.</p>,
    },
    {
        text: <p>Usted puede ponerse en contacto con nosotros a través de <a href={`mailto:${COMPANY_EMAIL}`}>{COMPANY_EMAIL}</a>, por teléfono al <a href={`tel:${COMPANY_PHONE}`}>{COMPANY_PHONE}</a> o por medio del formulario de contacto del sitio si estuviese disponible.</p>,
    },
    {
        text: <p>Toda modificación a la presente Política de Privacidad será previamente notificada vía correo electrónico a la dirección de correo por Ud. informada. En tal caso, se le proporcionará a usted la oportunidad de brindar su consentimiento -en tiempo y forma- a las nuevas modalidades dispuestas, respecto a los usos y prácticas de tratamiento de su información personal. En caso de no estar de acuerdo con dichas modificaciones Ud. deberá abstenerse de seguir utilizando los servicios provistos por PATATAG y la cuenta será dada de baja.</p>,
    },
    {
        text: <p>Los usuarios de PATATAG deberán hacer uso responsable de su cuenta y los servicios provistos por PATATAG. En cualquier momento, Ud. podrá solicitar la baja y la eliminación de su cuenta e información de la base de datos, como así también acceder y actualizar sus datos personales. </p>,
    },
    {
        text: <p>&quot;EL TITULAR DE LOS DATOS PERSONALES TIENE LA FACULTAD DE EJERCER EL DERECHO DE ACCESO A LOS MISMOS EN FORMA GRATUITA A INTERVALOS NO INFERIORES A SEIS MESES, SALVO QUE SE ACREDITE UN INTERÉS LEGÍTIMO AL EFECTO CONFORME LO ESTABLECIDO EN EL ARTÍCULO 14, INCISO 3 DE LA LEY Nº 25.326 DE LA REPUBLICA ARGENTINA.&quot;</p>,
    },
    {
        text: <p>&quot;LA AUTORIDAD DE APLICACIÓN DE LA LEY Nº 25.326, TIENE LA ATRIBUCIÓN DE ATENDER LAS DENUNCIAS Y RECLAMOS QUE SE INTERPONGAN CON RELACIÓN AL INCUMPLIMIENTO DE LAS NORMAS SOBRE PROTECCIÓN DE DATOS PERSONALES.&quot;</p>,
    },
    {
        text: <p>La Agencia de Acceso a la Información Pública es la autoridad de aplicación de la Ley de Protección de Datos Personales 25.326, con domicilio en Av. Pte. Gral. Julio A. Roca 710 - Piso 3º - Ciudad Autónoma de Buenos Aires, Argentina. Para mayor información, ingrese a: <a href='https://www.argentina.gob.ar/aaip' target='_blank' rel='noopener noreferrer'>https://www.argentina.gob.ar/aaip</a></p>,
    },
    {
        text: <p className='font-bold'>EL USO DE ESTE SITIO WEB NO ESTÁ AUTORIZADO EN AQUELLAS JURISDICCIONES EN QUE NO SE SATISFAGAN TODAS LAS PROVISIONES DE LAS PRESENTES POLITICAS DE PRIVACIDAD.</p>,
    },
];

export default function PrivacyPolicyModal({ disclosure }) {
    return (
        <Modal
            backdrop='opaque'
            isOpen={disclosure.isOpen}
            onOpenChange={disclosure.onOpenChange}
            placement='center'
            size="2xl"
            scrollBehavior="inside"
            classNames={{
                backdrop: 'bg-gradient-to-t from-zinc-900 to-zinc-900/10 backdrop-opacity-20',
                base: 'max-h-[90vh]',
                body: 'px-4 sm:px-6',
                header: 'px-4 sm:px-6',
                footer: 'px-4 sm:px-6'
            }}
        >
            <ModalContent>
                {(onClose) => (
                    <>
                        <ModalHeader className='modal-header flex justify-center items-center text-center relative'>
                            <h3 className='uppercase'>Política de Privacidad</h3>
                        </ModalHeader>

                        <ModalBody>
                            <div className='text-justify space-y-4 text-sm'>
                                {paragraphs.map((paragraph, index) => (
                                    <p key={index}>{paragraph.text}</p>
                                ))}
                            </div>
                        </ModalBody>

                        <ModalFooter className='flex flex-col gap-2 pt-0'>
                            <Button
                                color='default'
                                className='w-full uppercase shadow-lg mt-1 mx-auto'
                                radius='sm'
                                onPress={onClose}
                                style={{ maxWidth: '300px' }}
                            >
                                Cerrar
                            </Button>
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    );
} 