import { COMPANY_PHONE } from '../constants/contact.js';

const whatsAppURL = 'https://api.whatsapp.com/send/';
const supportPhone = COMPANY_PHONE.replace('+', '');

/**
 * Creates a WhatsApp URL for a given phone number and message.
 *
 * @param {string} phone - The phone number to send the message to
 * @param {string} message - The message to send
 * @returns {string} The WhatsApp URL
 */

export const createWhatsAppURL = (phone, message) => {
    const encodedMessage = encodeURIComponent(message);
    return `${whatsAppURL}?phone=${phone}&text=${encodedMessage}&type=phone_number&app_absent=0`;
};

/**
 * Creates a WhatsApp URL for the support phone number and a given message.
 *
 * @param {string} message - The message to send
 *
 * @returns {string} The WhatsApp URL
 */
export const createSupportMessage = (message) => {
    return createWhatsAppURL(supportPhone, message);
};

/**
 * Creates a WhatsApp URL for the support phone number and a message with the found pet information.
 *
 * @param {string} userName - The name of the user who found the pet
 * @param {string} petName - The name of the pet found
 * @param {number} latitude - The latitude of the pet's location
 * @param {number} longitude - The longitude of the pet's location
 *
 * @returns {string} The WhatsApp URL
 */
export const createFoundPetMessage = (userName, petName, latitude, longitude) => {
    const baseMessage = `Hola ${userName}, ¡encontré a ${petName}! ❤️`;
    const locationMessage = latitude && longitude ? 
        `\n\n📍 Ubicación: https://www.google.com/maps?q=${latitude},${longitude}` : '';
    return createWhatsAppURL(supportPhone, `${baseMessage}${locationMessage}`);
}; 