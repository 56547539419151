export const config = {
    frontend: {
        url: process.env.REACT_APP_FRONTEND_ENFPOINT || 'http://localhost:3000/',
    },
    api: {
        baseUrl: process.env.REACT_APP_ENDPOINT || 'http://localhost:8000/',
        apiUrl: process.env.REACT_APP_API_ENDPOINT || 'http://localhost:8000/api/',
    },
    auth: {
        googleClientId: process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID || '',
    }
};