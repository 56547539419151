import React from 'react';
import { Button } from '@nextui-org/react';

import background from '../../assets/images/banner.png';
import keyRing from '../../assets/images/key-ring.png';

import './Banner.css';

export default function Banner() {
    return (
        <div className='banner'>
            <div className='slide' style={{ backgroundImage: `url(${background})` }}>
                <div className='container flex gap-4 h-full'>
                    <div className='img-container'>
                        <img src={keyRing} alt='keyRing' className='keyRing' />
                    </div>

                    <div className='flex flex-col justify-center text-white'>
                        <h2 className='banner-title uppercase'>Protegé a quien<br />
                            <span>Más querés</span>
                        </h2>

                        <Button
                            color='primary'
                            className='bg-primary w-full max-w-[300px] mx-auto uppercase shadow-lg'
                            radius='sm'
                            onPress={() => window.location.href = '/login'}
                        >
                            ¡Accedé!
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}
