import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, Navbar as NavbarNextUI, NavbarBrand, NavbarContent, NavbarMenuToggle } from '@nextui-org/react';

import LogoFull from '../Icons/LogoFull.jsx';

import './Navbar.css';

const navLinks = [
    {
        id: 'login',
        to: '/login',
        title: 'Iniciar Sesión',
        roles: [null],
    },
    {
        id: 'register',
        to: '/registro',
        title: 'Registrarse',
        roles: [null],
    },
    {
        id: 'panelUsuario',
        to: '/panel-usuario',
        title: 'Panel de Usuario',
        roles: ['user', 'admin'],
    },
    {
        id: 'panelAdmin',
        to: '/panel-admin',
        title: 'Panel de Administrador',
        roles: ['admin'],
    },
    {
        id: 'logout',
        to: '/logout',
        title: 'Cerrar Sesión',
        roles: ['user', 'admin'],
    },
];

const mobileNavLinks = [
    {
        id: 'benefits',
        to: '#benefits',
        title: 'Beneficios',
        roles: [null, 'user', 'admin'],
    },
    {
        id: 'how-it-works',
        to: '#how-it-works',
        title: 'Cómo Funciona',
        roles: [null, 'user', 'admin'],
    },
    {
        id: 'reviews',
        to: '#reviews',
        title: 'Testimonios',
        roles: [null, 'user', 'admin'],
    },
    {
        id: 'faq',
        to: '#faq',
        title: 'Preguntas Frecuentes',
        roles: [null, 'user', 'admin'],
    },
    {
        id: 'about-us',
        to: '#about-us',
        title: 'Quiénes Somos',
        roles: [null, 'user', 'admin'],
    },
    ...navLinks,
];

Navbar.propTypes = {
    role: PropTypes.string,
};

export default function Navbar({ role }) {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const location = window.location.pathname;
    
    useEffect(() => {
        const handleClickOutside = (event) => {
            const sideNav = document.querySelector('.side-nav');
            const hamburgerMenu = document.getElementById('hamburgerMenu');
            
            if (isMenuOpen && sideNav && !sideNav.contains(event.target) && 
                hamburgerMenu && !hamburgerMenu.contains(event.target)) {
                setIsMenuOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isMenuOpen]);
    
    const handleLogoClick = (e) => {
        if (location === '/') {
            e.preventDefault();
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    };  
    
    const handleScroll = (event, target) => {
        event.preventDefault();
        const elementId = target.replace('#', '');
        
        if (location === '/') {
            const element = document.getElementById(elementId);
            if (element) {
                const yOffset = -80;    
                const y = element.getBoundingClientRect().top + window.scrollY + yOffset;
                window.scrollTo({ top: y, behavior: 'smooth' });
                setIsMenuOpen(false);
            }
        } else {
            window.location.href = `/#${elementId}`;
            setIsMenuOpen(false);
        }
    };

    const toggleMenu = (e) => {
        setIsMenuOpen(prevState => !prevState);
    };

    return (
        <NavbarNextUI
            id='navbar'
            isBordered
            isMenuOpen={isMenuOpen}
            className='bg-primary text-white relative'
        >
            {/* Desktop Menu */}
            <NavbarContent id='desktopMenu' className='flex align-center'>
                <NavbarBrand 
                    className='cursor-pointer'
                >
                    <Link to="/" onClick={handleLogoClick}>
                        <LogoFull />
                    </Link>
                </NavbarBrand>

                <div className='flex flex-row justify-end items-center gap-3'>
                    {navLinks.map((navLink) => {
                        if (navLink.roles.includes(role)) {
                            return (
                                <Link
                                    to={navLink.to}
                                    className='underlined-link'
                                    key={navLink.id}
                                >
                                    <Button
                                        key={navLink.id}
                                        className='uppercase color-dark p-2'
                                        variant='transparent'
                                        radius='none'
                                    >
                                        {navLink.title}
                                    </Button>
                                </Link>
                            );
                        }

                        return null;
                    })}
                </div>
            </NavbarContent>

            {/* Hamburger menu */}
            <NavbarContent
                id='hamburgerMenu'
                className='color-light'
            >
                <NavbarMenuToggle
                    aria-label={isMenuOpen ? 'Close menu' : 'Open menu'}
                    onPress={(e) => toggleMenu(e)}
                />
            </NavbarContent>
            
            {/* Logo mobile */}
            <NavbarContent id='logoMobile' className='flex w-full' justify='center'>
                <Link to='/' onClick={handleLogoClick} className=''>
                    <NavbarBrand>
                        <LogoFull />
                    </NavbarBrand>
                </Link>
            </NavbarContent>

            {/* Mobile */}
            <nav className={`side-nav ${isMenuOpen ? 'open' : ''}`}>
                {mobileNavLinks.map((navLink) => {
                    if (navLink.roles.includes(role)) {
                        return navLink.to.includes('#') ? (
                            <a 
                                href={navLink.to} 
                                key={navLink.id} 
                                onClick={(event) => handleScroll(event, navLink.to)}
                            >
                                {navLink.title}
                            </a>
                        ) : (
                            <Link 
                                to={navLink.to} 
                                key={navLink.id}
                                onClick={() => setIsMenuOpen(false)}
                            >
                                {navLink.title}
                            </Link>
                        );
                    }
                    return null;
                })}
            </nav>
        </NavbarNextUI>
    );
}