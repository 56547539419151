/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import userService from '../../services/users.js';

import AboutUs from '../../components/AboutUs/AboutUs.jsx';
import Banner from '../../components/Banner/Banner.tsx';
import Benefits from '../../components/Benefits/Benefits.jsx';
import { Reviews } from '../../components/Reviews/Reviews.tsx';
import FAQs from '../../components/FAQs/FAQs.jsx';
import HowItWorks from '../../components/HowItWorks/HowItWorks.jsx';
import PageContent from '../../components/PageContent/PageContent.jsx';

import './Homepage.css';

Homepage.propTypes = {
    userRole: PropTypes.string,
    setUserRole: PropTypes.func,
};


export default function Homepage() {
    const location = useLocation();
    const [userRole, setUserRole] = useState(userService.getUserFromLocalStorage());
 
    useEffect(() => {
        const checkSession = async () => {
            try {
                const response = await userService.checkSession();

                if (response.success) {
                    setUserRole(response.data.role);
                } else {
                    setUserRole(null);
                }
            } catch (error) {
                setUserRole(null);
            }
        };

        checkSession();
    }, []);

    useEffect(() => {
        if ('scrollRestoration' in history) {
            history.scrollRestoration = 'manual';
        }

        if (!location.hash) {
            window.scrollTo(0, 0);
        }

        if (location.hash) {
            setTimeout(() => {
                const elementId = location.hash.replace('#', '');
                const element = document.getElementById(elementId);
                
                if (element) {
                    const yOffset = -80;
                    const y = element.getBoundingClientRect().top + window.scrollY + yOffset;
                    window.scrollTo({ top: y, behavior: 'smooth' });
                }
            }, 100);
        }

        return () => {
            if ('scrollRestoration' in history) {
                history.scrollRestoration = 'auto';
            }
        };
    }, [location]);

    return (
        <div id='homepage'>
            <PageContent withBackground={false} userRole={userRole}>
                <Banner />

                <Benefits />
                
                <HowItWorks />

                <Reviews />

                <FAQs />

                <AboutUs />
            </PageContent>
        </div>
    );
}

