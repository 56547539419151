import React from 'react';

import FadeInDiv from '../Animated/FadeInDiv.jsx';
import LogoFull from '../Icons/LogoFull.jsx';
import HandScanQR from '../Icons/HandScanQR.jsx';
import Diffusion from '../Icons/Diffusion.jsx';
import MapPoint from '../Icons/MapPoint.jsx';

import './Benefits.css';

const benefits = [
    {
        title: ['Todo en un Tag', 'Inteligente'],
        icon: <HandScanQR className='fill-dark' />,
    },
    {
        title: ['Detección de', 'Ubicación GPS'],
        icon: <MapPoint />,
    },
    {
        title: ['Máxima difusión', 'por extravío'],
        icon: <Diffusion className='fill-dark' />,
    },
];


export default function Benefits() {
    return (
        <div id='benefits' className='benefits bg-primary-light p-6'>
            <div className='container my-4'>
                <div className='title flex justify-center items-center'>
                    <FadeInDiv>
                        <h2 className='text-center text-white font-bold mr-2'>Beneficios de</h2>
                        <LogoFull className='fill-dark-light' />
                    </FadeInDiv>
                </div>

                <div className='flex flex-wrap justify-center gap-8 mt-6'>
                    {benefits.map((benefit, index) => (
                        <FadeInDiv key={index} viewportAmount={0.5} delay={index * 0.2}>
                            <div key={index} className='item flex flex-col justify-between bg-white color-primary shadow-lg p-4 w-full'>
                                <div className='front-card flex flex-col justify-center items-center flex-1'>
                                    {benefit.icon}
                                    <h3 className='text-center uppercase fw-700 mt-4'>{benefit.title[0]}</h3>
                                    <h3 className='text-center uppercase fw-700'>{benefit.title[1]}</h3>
                                </div>
                            </div>
                        </FadeInDiv>
                    ))}
                </div>
            </div>
        </div>
    );
}
