import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { NextUIProvider } from '@nextui-org/react';
import { GoogleOAuthProvider } from '@react-oauth/google';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <NextUIProvider>
        <GoogleOAuthProvider clientId='819235475988-i4ubbjvfnp9ajqrkpo6cp81eijs4kfes.apps.googleusercontent.com'>
            <App />
        </GoogleOAuthProvider>
    </NextUIProvider>
);
