import React from 'react';
import { useDisclosure } from '@nextui-org/react';

import PrivacyPolicyModal from './components/PrivacyPolicyModal.jsx';
import TermsModal from './components/TermsModal.jsx';

import Instagram from '../Icons/Instagram.jsx';
import LogoIcon from '../Icons/Logo.jsx';
import WhatsApp from '../Icons/WhatsApp.jsx';
import { COMPANY_PHONE } from '../../constants/contact';

import './Footer.css';

const currentYear = new Date().getFullYear();

export default function Footer() {
    const privacyPolicyModal = useDisclosure();
    const termsModal = useDisclosure();

    return (
        <>
            <div id='footer' className='flex flex-col bg-dark color-light p-4'>
                <div className='container'>
                    <div className='flex justify-center items-center gap-4 mb-2'>
                        <LogoIcon />
                        <div className='flex flex-col'>
                            <p>&copy; {currentYear} - PATATAG®</p>
                            <p>Todos los derechos reservados</p>
                        </div>
                    </div>

                    <div className='content flex flex-wrap justify-center items-center gap-2 text-center w-fit mx-auto'>
                        <div className='flex flex-wrap items-center justify-center gap-2'>
                            <p 
                                className='cursor-pointer hover:underline'
                                onClick={() => privacyPolicyModal.onOpen()}
                            >
                                Política de privacidad
                            </p>
                            
                            <span className='separator-1 px-2'>|</span>
                            
                            <p 
                                className='cursor-pointer hover:underline'
                                onClick={() => termsModal.onOpen()}
                            >
                                Términos y condiciones
                            </p>
                        </div>
                        
                        <span className='separator-2 px-2'>|</span>
                        
                        <div className='flex items-center gap-2'>
                            <a href={`https://wa.me/${COMPANY_PHONE}`} target='_blank' rel='noreferrer'>
                                <WhatsApp className='w-4 h-5' />
                            </a>
                            
                            <span className='px-2'>|</span>
                            
                            <a href='https://www.instagram.com/patatag.ok/' target='_blank' rel='noreferrer'>
                                <Instagram className='w-4 h-5' />
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <PrivacyPolicyModal disclosure={privacyPolicyModal} />
            <TermsModal 
                disclosure={termsModal} 
                onOpenPrivacyPolicy={privacyPolicyModal.onOpen}
            />
        </>
    );
}
