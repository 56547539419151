import React from 'react';
import { Divider } from '@nextui-org/react';
import { createSupportMessage } from '../../utils/whatsapp';

import FadeInDiv from '../Animated/FadeInDiv.jsx';
import PawFilled from '../Icons/PawFilled';

import petsImage from '../../assets/images/pet-and-cat.png';
import './AboutUs.css';

const supportMessage = 'Hola, necesito ayuda con mi mascota.';

const aboutUs = [
    {
        title: 'Sobre Nosotros',
        text: <p>Somos un equipo de personas apasionadas por los animales. Estamos siempre acá para vos, listos para ayudarte en todo lo que tu mascota necesite. Nos destacamos por nuestra solidaridad y nuestro profundo amor y respeto por todos los seres vivos.</p>
    },
    {
        title: 'Nuestra Misión',
        text: <p>Nuestra misión es garantizar la seguridad de tu mascota y darte la tranquilidad de que no vamos a parar de buscar hasta que tu mascota vuelva a casa, en caso de extravío.</p>
    },
    {
        title: 'Nuestra Comunidad',
        text: <p>Creamos y fomentamos una comunidad basada en la solidaridad, amor y respeto. Te extendemos la invitación para unirte a nuestra familia y compartir el compromiso por la seguridad y el bienestar de nuestras queridas mascotas.</p>
    },
    {
        title: 'Contacto',
        text: <p>Contactanos a través de nuestro Instagram <a className='underlined-link underlined-link-primary fw-bold' href='https://www.instagram.com/patatag.ok' target='_blank' rel='noopener noreferrer'>@patatag.ok</a> o escribinos directamente a nuestro <a className='underlined-link underlined-link-primary fw-bold' href={createSupportMessage(supportMessage)}>WhatsApp</a>.</p>
    }
];


export default function AboutUs() {
    return (
        <div id='about-us' className='about-us py-8 px-4'>
            <FadeInDiv>
                <div className='flex justify-center items-end'>
                    <h2 className='title color-primary text-center'>
                        ¿Quiénes
                        <br />
                        somos?
                    </h2>

                    <img src={petsImage} alt='Pets' className='pet-image' />
                </div>
            </FadeInDiv>

            {/* Text */}
            <div className='text-dark-light'>
                {aboutUs.map((section, index) => {
                    return (
                        <FadeInDiv key={index}>
                            <div className='mb-6'>
                                <div className='flex'>
                                    <PawFilled className='paw-filled mr-2' />
                                    <h3 className='font-semibold uppercase'>{section.title}</h3>
                                </div>

                                <Divider className='bg-primary my-2' />

                                {section.text}
                            </div>
                        </FadeInDiv>
                    );
                })}
            </div>
        </div>
    );
}
