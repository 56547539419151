import React from 'react';
import { Accordion, AccordionItem } from '@nextui-org/react';
import { createSupportMessage } from '../../utils/whatsapp';
import { COMPANY_PHONE } from '../../constants/contact.js';

import FadeInDiv from '../Animated/FadeInDiv.jsx';
import ChevronRight from '../Icons/ChevronRight.jsx';
import PawFilled from '../Icons/PawFilled';

import './FAQs.css';

const customTagMessage = '¡Hola! Me gustaría obtener Tags personalizados para mi organización. 🏷️';
const lostPetMessage = '¡Hola! Mi mascota se extravió y necesito ayuda urgente. 🆘';

const questions = [
    {
        question: '¿Qué es un Tag?',
        answer: <>
            <p>Un <span className='fw-bold'>Tag</span> es una identificación QR que almacena toda la información de tu mascota y tus datos de contacto.</p>
            <p>Es importante que tu mascota <span className='fw-bold'>siempre lo lleve consigo</span>. En caso de extravío, quien la encuentre podrá <span className='fw-bold'>escanear el QR y contactarte rápidamente.</span></p>
        </>
    },
    {
        question: '¿Cómo obtengo un Tag?',
        answer: <p>Podés conseguir Tags en <span className='fw-bold'>veterinarias, petshops y protectoras de animales</span> en tu ciudad. También podés <span className='fw-bold'>generarlo y fabricarlo vos mismo</span> de forma gratuita. Si necesitás ayuda, escribinos por <a className='underlined-link underlined-link-dark fw-bold' href={createSupportMessage('')}>WhatsApp</a> y te asistiremos.</p>
    },
    {
        question: '¿Cómo obtengo Tags con el logo de mi organización?',
        answer: <p>Si querés <span className='fw-bold'>Tags personalizados con tu logo</span>, escribinos por <a className='underlined-link underlined-link-dark fw-bold' href={createSupportMessage(customTagMessage)}>WhatsApp</a>. Podemos diseñarlos y fabricarlos para que representen a tu organización y ayuden a mantener seguras a más mascotas.</p>
    },
    {
        question: '¿Cómo vinculo un Tag a mi mascota?',
        answer: <>
            <p>Al crear el perfil de tu mascota, se genera un <span className='fw-bold'>código QR gratuito</span> que podés <span className='fw-bold'>descargar, imprimir y usar</span>.</p>
            <p>Si tenés un <span className='fw-bold'>Tag</span>, ingresá al perfil de tu mascota y seleccioná <span className='fw-bold'>"Vincular otro Tag"</span> para reemplazar el QR generado automáticamente.</p>
        </>
    },
    {
        question: '¿Qué hago si mi mascota se pierde?',
        answer: <>
            <p><span className='fw-bold'>• Reportalo en nuestra</span> <a className='underlined-link text-light' href='https://www.appsheet.com/start/3f4a710b-f5bf-4b4f-9e11-6fa0fb5aa4c8' target='_blank' rel='noopener noreferrer'>App de Mascotas Perdidas</a> para que más personas en la zona puedan ayudarte a encontrarla.</p>
            <p><span className='fw-bold'>• Difusión instantánea:</span> Alertamos a la comunidad para aumentar las chances de que vuelva a casa.</p>
            <p><span className='fw-bold'>• Ubicación GPS:</span> Te avisaremos en cuanto alguien escanee su Tag.</p>
            
            <p>Si se perdió tu mascota, avisanos enseguida por <a className='underlined-link underlined-link-dark fw-bold' href={createSupportMessage(lostPetMessage)}>WhatsApp</a>. Actuar rápido es fundamental.</p>
        </>
    },
    {
        question: '¿Por qué PATATAG es gratis?',
        answer: <p>Queremos que <span className='fw-bold'>todas las mascotas estén identificadas y protegidas</span>. Por eso, creamos un sistema <span className='fw-bold'>totalmente gratuito</span>, donde podés <span className='fw-bold'>generar y usar tu código QR sin ningún costo</span>.</p>
    },
    {
        question: '¿Cómo obtener ayuda o más información?',
        answer: <p>Escribinos por <a className='underlined-link underlined-link-dark fw-bold' href={createSupportMessage('')}>WhatsApp</a> para recibir atención personalizada en cualquier momento y seguinos en Instagram <a className='underlined-link underlined-link-dark fw-bold' href='https://www.instagram.com/patatag.ok' target='_blank' rel='noopener noreferrer'>@patatag.ok</a> para enterarte de todas las novedades.</p>
    }
];


export default function FAQs() {
    return (
        <div id='faq' className='faq bg-primary py-8 px-2'>
            {/* Title */}
            <FadeInDiv>
                <h2 className='title text-center my-4'><span className='text-white bold'>Preguntas</span> Frecuentes</h2>
            </FadeInDiv>

            {/* Preguntas */}
            <Accordion
                variant='light'
                className='accordion px-2 mx-auto'
            >
                {questions && questions.map((question, index) => {
                    return (
                        <AccordionItem
                            key={index}
                            aria-label={question.question}
                            startContent={<PawFilled className='paw-filled q-6 h-6' />}
                            title={question.question}
                            indicator={<ChevronRight className='q-6 h-6' />}
                            classNames={{
                                heading: 'py-3',
                                trigger: 'py-0',
                            }}
                        >
                            <div className='answer'>{question.answer}</div>
                        </AccordionItem>
                    );
                })}
            </Accordion>
        </div>
    );
}
