import React from 'react';
import Slider from 'react-slick';

import { Review } from '../Review/Review.tsx';
import FadeInDiv from '../Animated/FadeInDiv.jsx';

import ChevronLeft from '../Icons/ChevronLeft.jsx';
import ChevronRight from '../Icons/ChevronRight.jsx';
import mariaTobyImg from '../../assets/images/reviews/maria_y_toby.jpg';
import florBellaImg from '../../assets/images/reviews/flor_y_bella.jpg';
import camilaLunaImg from '../../assets/images/reviews/camila_y_luna.jpg';
import marcosRockyImg from '../../assets/images/reviews/marcos_y_rocky.jpg';

const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: <ChevronLeft />,
    nextArrow: <ChevronRight />,
    responsive: [
        {
            breakpoint: 1050,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 750,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            }
        },
    ]
};

 
const slides = [
    {
        name: 'María',
        petName: 'Toby',
        petPhoto: mariaTobyImg,
        rating: 5,
        review: ['Cuando Toby se escapó PATATAG creó un video viral en Instagram.', 'Al día siguiente, alguien lo encontró y me contactó por WhatsApp.', 'Estoy encantada con el servicio.']
    },
    {
        name: 'Flor',
        petName: 'Bella',
        petPhoto: florBellaImg,
        rating: 5,
        review: ['Cuando Bella se perdió PATATAG estuvo presente en todo momento.', 'Por suerte, a los pocos días, me llegó la ubicación GPS de Bella y un mensaje de WhatsApp.']
    },
    {
        name: 'Camila',
        petName: 'Luna',
        petPhoto: camilaLunaImg,
        rating: 5,
        review: ['Saber que mi gatita Luna lleva su Tag con toda su información me da una gran tranquilidad.', 'Si alguna vez se pierde, sé que será mucho más fácil su vuelta a casa.']
    },
    {
        name: 'Marcos',
        petName: 'Rocky',
        petPhoto: marcosRockyImg,
        rating: 5,
        review: ['Rocky tiene diabetes y necesita cuidados especiales.', 'Me siento muy tranquilo teniendo toda su información médica disponible en un solo Tag.']
    },
];


export const Reviews: React.FC = () => {
    return (
        <div id='reviews' className='reviews bg-light-gray py-8 px-2'>
            <div className='container my-4'>
                <FadeInDiv>
                    <div className='flex justify-center items-center'>
                        <h2 className='text-center font-bold mr-2'>¿Qué dicen de nosotros?</h2>
                    </div>
                </FadeInDiv>

                <FadeInDiv>
                    <Slider {...sliderSettings}>
                        {slides.map((review, index) => (
                            <Review key={index} review={review} index={index} />
                        ))}
                    </Slider>
                </FadeInDiv>
            </div>
        </div>
    );
}
