import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button } from '@nextui-org/react';
import { COMPANY_PHONE, COMPANY_EMAIL } from '../../../constants/contact.js';

TermsModal.propTypes = {
    disclosure: PropTypes.object.isRequired,
    onOpenPrivacyPolicy: PropTypes.func.isRequired
};


export default function TermsModal({ disclosure, onOpenPrivacyPolicy }) {
    const paragraphs = [
        {
            text: <p className='font-bold'>1. INTRODUCCIÓN.</p>,
        },
        {
            text: <p><span className='font-bold'>1.1</span> PATATAG le da la bienvenida y le invita a leer atentamente los “Términos y Condiciones de uso”, los cuales regulan la relación con el usuario y describen las modalidades y condiciones -conforme lo establecido en la normativa vigente en la República Argentina- aplicables a la navegación y/o registro y/o a la adquisición de los servicios ofrecidos en el sitio.</p>,
        },
        {
            text: <p><span className='font-bold'>1.2 ACEPTACIÓN.</span> Antes de acceder a cualquier contenido, información o materiales puestos a disposición en el sitio <a href='https://patatag.com/' target='_blank' rel='noreferrer'>https://patatag.com/</a>  -en adelante “el sitio” o “la Plataforma” o “PATATAG” debe leerse completa y atentamente este documento. Al acceder y/o utilizar el Sitio y/o registrarse, usted acepta -sin reservas- estos Términos y Condiciones de uso (en adelante “Términos y Condiciones”). Si usted no está de acuerdo con estos Términos y Condiciones, por favor no acceda ni utilice de ninguna forma el Sitio, ni intente registrarse.</p>,
        },
        {
            text: <p><span className='font-bold'>1.3 VERSIONES.</span> PATATAG es una plataforma web que se encuentra en versión Beta, es decir, en etapa de prueba y mejora permanente. La plataforma se ofrece AS IS, es decir, en el estado que se encuentra y el usuario acepta que todos los servicios y herramientas que se ofrecen en el sitio podrían contener errores o fallas de funcionamiento.</p>,
        },
        {
            text: <p><span className='font-bold'>1.4 REVISIÓN DE LOS TÉRMINOS Y CONDICIONES.</span> Toda modificación a estos “Términos y Condiciones” serán publicadas en nuestro sitio. Las modificaciones que eventualmente se pudieran realizar a los “Términos y Condiciones” se harán efectivas luego de su publicación, con la indicación precisa de la fecha de modificación respectiva. El uso continuado de este sitio tras la publicación de las revisiones a estos “Términos y Condiciones” significará que usted acepta esas modificaciones. En caso de no estar de acuerdo con dichas modificaciones Ud. deberá abstenerse de seguir utilizando el sitio y/o la plataforma. En caso de registro, cualquier modificación a los términos y condiciones específicos para usuarios registrados será notificada por correo electrónico a la casilla indicada por el usuario.</p>,
        },
        {
            text: <p><span className='font-bold'>1.5 POLÍTICA DE PRIVACIDAD.</span> En adición a estos Términos y Condiciones, hemos dispuesto una Política de Privacidad para explicar los modos en que recopilamos y utilizamos la información. Puede encontrar la Política de Privacidad haciendo clic <span className='text-primary cursor-pointer hover:underline' onClick={() => { onOpenPrivacyPolicy(); }}>aquí</span>. Este documento se incorpora por referencia y forma parte integrante de estos Términos y Condiciones. Al acceder o utilizar el sitio PATATAG, usted también está dando su aceptación y reconocimiento a nuestra Política de Privacidad.</p>,
        },
        {
            text: <p><span className='font-bold'>2. RESPONSABLE DEL SITIO.</span> En estos Términos y Condiciones las palabras &quot;plataforma&quot;, &quot;empresa&quot;, &quot;nosotros&quot;, &quot;nos&quot; y &quot;nuestros&quot;, se refieren a &quot;PATATAG&quot;. Usted estará contratando con Francisco Meyle Ritacco CUIT 20-38900728-6, con domicilio en la calle Agrelo 1381, Rosario, Santa Fe, Argentina, teléfono <a href={`tel:${COMPANY_PHONE}`}>{COMPANY_PHONE}</a> y domicilio electrónico en <a href={`mailto:${COMPANY_EMAIL}`}>{COMPANY_EMAIL}</a>.</p>,
        },

        {
            text: <p><span className='font-bold'>3. LA PLATAFORMA.</span></p>,
        },
        {
            text: <p><span className='font-bold'>3.1</span> PATATAG es una plataforma web que sirve a los usuarios, dueños y dueñas de mascotas, en adelante, “dueños” o “usuarios” para brindar información de su mascota en un código QR, y así proteger e intentar ubicar a sus mascotas en caso de que la mascota sea extraviada. El servicio de disponibilidad el QR es gratuito, si el usuario desea colocarlo en un llavero, o “TAG”, deberá comprarlo abonando el correspondiente costo asociado a ello. </p>,
        },
        {
            text: <p><span className='font-bold'>3.2</span> A continuación se describe el mecanismo de funcionamiento de PATATAG:</p>,
        },
        {
            text: <p className='ps-4'><span className='px-2'>a.</span>El usuario ingresa al sitio PATATAG y se crea una cuenta con su dirección de correo electrónico y contraseña. </p>,
        },
        {
            text: <p className='ps-4'><span className='px-2'>b.</span>Dentro de su cuenta, puede crear uno o más perfiles para sus mascotas, cargando la información de su/s mascota/s. </p>,
        },
        {
            text: <p className='ps-4'><span className='px-2'>c.</span>Al crear el perfil de una mascota, el sistema genera automáticamente un código QR único asociado a ese perfil. </p>,
        },
        {
            text: <p className='ps-4'><span className='px-2'>d.</span>El usuario puede descargar este QR para luego imprimirlo y usarlo.</p>,
        },
        {
            text: <p className='ps-4'><span className='px-2'>e.</span>Si el usuario prefiere no imprimir el QR generado automáticamente, tiene la opción de adquirir un llavero (denominado, en adelante “TAG”).</p>,
        },
        {
            text: <p className='ps-4'><span className='px-2'>f.</span>Este TAG incluye un código QR único que, al momento de la compra, no está vinculado a ninguna mascota.</p>,
        },
        {
            text: <p className='ps-4'><span className='px-2'>g.</span>El usuario debe vincular el QR del TAG al perfil de una de sus mascotas a través de la plataforma.</p>,
        },
        {
            text: <p className='ps-4'><span className='px-2'>h.</span>Una vez realizada esta vinculación, el QR generado automáticamente por el sistema al crear el perfil de la mascota queda sin efecto y ya no puede ser utilizado.</p>,
        },
        {
            text: <p><span className='font-bold'>3.3</span> Los TAG pueden ser comprados, pagando el costo asociado, en lugares como veterinarias, petshops, protectoras de animales y/o similares.  Por lo que, el servicio de disponibilidad el QR es gratuito, pero no así el TAG o llavero que puede contenerlo.</p>,
        },
        {
            text: <p><span className='font-bold'>3.4</span> En caso de pérdida o extravío del animal, si alguien escanea ese código QR, llegará una notificación vía correo electrónico al mail indicado en su perfil. Dicha notificación puede contener información de geolocalización aproximada si el dispositivo con el cual se escanea el QR, permite los accesos de georreferencia, o puede ser obtenida a través de la dirección IP del dispositivo que escanea el QR, entre otros puntos. Además, al contener información de contacto, la persona que escanee el QR podrá contactarse directamente con el dueño de la mascota. En caso de que se contacte con PATATAG, PATATAG dará aviso al dueño registrado.</p>,
        },
        {
            text: <p><span className='font-bold'>3.5</span> De manera adicional, en caso de pérdida o extravío de mascotas, los dueños podrán avisar a la plataforma para que ésta genere alertas al resto de usuarios y/o comparta fotos y videos en las redes sociales de la plataforma para dar difusión y aumentar las probabilidades de encontrar a la mascota extraviada.</p>,
        },
        {
            text: <p><span className='font-bold'>3.6</span> El sistema de generación y administración de códigos QR puede ser provisto por un tercero ajeno a PATATAG, por el cual PATATAG no es responsable por errores o fallas en su funcionamiento, por el contenido publicitario fuera de su control ni por cualquier error del usuario en su interacción con el sitio.</p>,
        },
        {
            text: <p><span className='font-bold'>3.7</span> Son las personas humanas con capacidad legal para contratar que navegan en el sitio, las que se registran con la finalidad de crear perfiles y asignar un código QR a sus mascotas y las que escanean los códigos QR contenidos en TAG con la finalidad de dar aviso de haber encontrado una mascota extraviada. No podrán ser usuarios las personas que no tengan capacidad para contratar.</p>,
        },
        {
            text: <p><span className='font-bold'>3.8</span> VERACIDAD Y ACTUALIZACIÓN DE DATOS. El usuario, al registrarse, presta declaración jurada respecto a la veracidad de los datos ingresados y será responsable por cualquier dato erróneo o falso del usuario o de su mascota y de los daños y perjuicios pudiere ocasionar, a PATATAG o a terceros, sin ningún tipo de limitación y debiendo mantener indemne a PATATAG. Además, se compromete a mantenerlos debidamente actualizados, pudiendo PATATAG solicitar datos adicionales, corroborar y/o confirmar los datos recibidos a través de información obrante en entidades públicas y/o privadas.</p>,
        },
        {
            text: <p><span className='font-bold'>3.9</span> FACULTADES RESERVADAS. PATATAG se reserva el derecho, a su sola discreción, de rechazar o eliminar cualquier registro sin que ello genere responsabilidad.</p>,
        },
        {
            text: <p><span className='font-bold'>4. PROPIEDAD INTELECTUAL Y CONTENIDO DEL SITIO</span></p>,
        },
        {
            text: <p><span className='font-bold'>4.1</span> PATATAG es la propietaria de todos los derechos de propiedad intelectual tanto del sitio accesible en <a href='https://patatag.com/' target='_blank' rel='noreferrer'>www.patatag.com</a>, incluyendo tanto de los derechos de autor de todo el software asociado, logotipos, texto y gráficos, y cualquier otra obra accesible en el sitio, así como de las marcas, patentes y modelos y diseños industriales que pudiesen estar involucrados. Los usuarios se comprometen a no utilizar de ninguna forma la propiedad intelectual de PATATAG sin previa autorización.</p>,
        },
        {
            text: <p><span className='font-bold'>4.2</span> Toda la información, materiales y archivos digitales en cualquier formato -incluyendo sin limitación, archivos de texto, audio, video, imágenes, descripciones de productos, software y cualquier otro contenido- disponible en PATATAG (en conjunto, el &quot;Contenido de PATATAG&quot;) son obras protegidas por derechos de autor de PATATAG o sus licenciantes. PATATAG y sus licenciantes conservan expresamente todos los derechos de propiedad intelectual sobre el Contenido de PATATAG. Salvo lo expresamente permitido en estos Términos y Condiciones, cualquier uso no autorizado del Contenido de PATATAG podría violar las leyes de propiedad intelectual u otras leyes aplicables.</p>,
        },
        {
            text: <p><span className='font-bold'>4.3</span> CONTENIDO DE TERCEROS. Además, el Sitio y la Plataforma pueden contener información, materiales y archivos digitales en cualquier formato proporcionados a PATATAG por terceros (en conjunto, el &quot;Contenido de Terceros&quot;). El Contenido de Terceros consiste en obras protegidas por derechos de autor o marcas de sus respectivos titulares, los cuales retienen expresamente todos los derechos de propiedad intelectual que la legislación les confiere sobre sus respectivas obras y marcas. Asimismo, el Contenido de Terceros puede estar sujeto también a términos y condiciones de uso y/o políticas de privacidad y/o licencias diferentes y/o adicionales impuestas por dichos terceros. Por favor, póngase en contacto con el titular correspondiente para más información sobre cualquiera de estos términos diferentes y/o adicionales de uso aplicable a Contenido de Terceros.</p>,
        },
        {
            text: <p><span className='font-bold'>4.4</span> CONTENIDO DE USUARIO. Si Usted se registra, accede a la Plataforma y elige mostrar, publicar, enviar o poner a disposición de terceros, en la Plataforma cualquier contenido (ya sean datos personales, o de su mascota, de contacto, laborales, y/o cualquier obra protegida por derechos de autor, incluyendo, sin limitación, imágenes, software, isologotipos, archivos de audio, texto, video o cualquier otro material en cualquier formato digital (colectivamente, el “Contenido de Usuario”), Usted otorga a PATATAG una licencia perpetua, irrevocable, libre de regalías, mundial, no exclusiva, incluyendo el derecho a conceder sublicencias a terceros, para usar, reproducir, transformar, comunicar, poner a disposición públicamente y distribuir dicho Contenido de Usuario, con la finalidad de poder brindarle los servicios que ofrece la Plataforma y mejorar la experiencia de usuario. Además, por la presente, Usted declara irrevocablemente y garantiza a PATATAG que: 1) cuenta con la titularidad o facultades suficientes y/o las licencias necesarias sobre el Contenido de Usuario; 2) la publicación del Contenido de Usuario y el ejercicio por parte de PATATAG de la licencia correspondiente no viola ninguna ley o infringe ningún derecho de propiedad intelectual de terceros. Usted acuerda indemnizar, defender y mantener indemne a PATATAG, sus administradores, socios, empleados, contratistas o agentes contra cualquier reclamo de terceros que surja relacionado con cualquier conflicto derivado del Contenido de Usuario aportado por Ud. en infracción a derechos de terceros y/o por el incumplimiento de las garantías dadas a PATATAG.</p>,
        },
        {
            text: <p><span className='font-bold'>5. RESTRICCIONES DE USO. SEGURIDAD.</span> Al utilizar el sitio como usuario registrado, Usted asume los siguientes compromisos:</p>,
        },
        {
            text: <p className='ps-4'>- No utilizará PATATAG para actos ilícitos, engañosos, malintencionados o discriminatorios.</p>,
        },
        {
            text: <p className='ps-4'>- No proporcionará información personal falsa, ni creará una cuenta para otras personas sin su autorización.</p>,
        },
        {
            text: <p className='ps-4'>- No creará más de una cuenta personal.</p>,
        },
        {
            text: <p className='ps-4'>- Mantendrá la información de contacto exacta y actualizada.</p>,
        },
        {
            text: <p className='ps-4'>- No compartirá su contraseña, ni dejará que otra persona acceda a su cuenta, tampoco hará nada que pueda poner en peligro la seguridad de su cuenta.</p>,
        },
        {
            text: <p className='ps-4'>- No realizará ninguna acción que pudiera inhabilitar, sobrecargar o afectar al funcionamiento correcto o el aspecto de PATATAG.</p>,
        },
        {
            text: <p className='ps-4'>- No intentará obtener acceso no autorizado a PATATAG ni a otras cuentas alojadas en la plataforma u otros sistemas informáticos o redes no cubiertos por estos Términos y Condiciones, para los cuales no tenga permiso de acceso.</p>,
        },
        {
            text: <p className='ps-4'>- No intentará acceder o utilizar PATATAG de manera que se evite incurrir en los eventuales costos del servicio u ocultar el uso que exceda los límites de uso, si los hubiera. </p>,
        },
        {
            text: <p className='ps-4'>- Si PATATAG inhabilita su cuenta, no creará otra sin permiso.</p>,
        },
        {
            text: <p className='ps-4'>- No recopilará información o contenido de otros usuarios, ni accederá de otro modo al sitio, utilizando medios automáticos (como harvesting bots, robots, arañas o scrapers) sin permiso.</p>,
        },
        {
            text: <p className='ps-4'>- No realizará ingeniería inversa ni descompilará ningún aspecto o contenido del sitio y/o de la plataforma. </p>,
        },
        {
            text: <p className='ps-4'>- No publicará comunicaciones comerciales no autorizadas (como correo no deseado, &quot;spam&quot;, cartas en cadena, esquemas piramidales o cualquier otra forma de mensajes duplicados o no solicitados).</p>,
        },
        {
            text: <p className='ps-4'>- No eliminará, ocultará o alterará cualquier aviso de derecho de propiedad relacionado con Contenido del Sitio y/o del Usuario.</p>,
        },
        {
            text: <p className='ps-4'>- No usará, ni controlará el desempeño o funcionalidad de la plataforma para cualquier propósito competitivo. </p>,
        },
        {
            text: <p className='ps-4'>- No usará la plataforma ni el Contenido del Sitio y/o del Usuario para cualquier otro propósito que no sea expresamente permitido por estos Términos y Condiciones.</p>,
        },
        {
            text: <p><span className='font-bold'>6. COSTO DEL SERVICIO.</span> No existe un costo asociado para que el usuario se cree un perfil y cargue información de su mascota en un código QR. En caso de establecer costos por ese servicio o servicios adicionales los usuarios serán debidamente notificados por correo electrónico a la casilla declarada al registrarse.  </p>,
        },
        {
            text: <p><span className='font-bold'>7. FALTA DE GARANTÍAS.</span> PATATAG no ofrece ninguna garantía sobre los servicios que se prestan en la plataforma. El cumplimiento de la normativa administrativa por parte de los usuarios es responsabilidad pura y exclusiva de éstos, desligando a PATATAG por cualquier incumplimiento. Los Usuarios reconocen que una de las principales funciones de PATATAG es meramente la de brindar información en un código QR, para que en caso de extravío de la mascota, si alguien lo encontrara se pueda contactar al usuario e intentar que la mascota se reencuentre con su dueña/o, pero ésto de ningún modo es garantizado por PATATAG. Es por ello que, mediante el acceso a la plataforma, los usuarios y las personas que escanean el QR, reconocen que ese contacto y cuidado del animal mientras tanto es absolutamente ajeno a PATATAG. </p>,
        },
        {
            text: <p><span className='font-bold'>8. LIMITACIONES DE LA RESPONSABILIDAD. </span></p>,
        },
        {
            text: <p className='ps-4'>El Sitio no garantiza la precisión o disponibilidad de la información proporcionada tras el escaneo del QR, ni asegura la recuperación de la mascota. PATATAG no se responsabiliza por:</p>,
        },
        {
            text: <p className='ps-4'>• Fallos técnicos, interrupciones o errores derivados del generador de códigos QR.</p>,
        },
        {
            text: <p className='ps-4'>• Información incorrecta o incompleta proporcionada por dispositivos escaneadores.</p>,
        },
        {
            text: <p className='ps-4'>• Daños derivados del uso indebido del sistema por parte de terceros.</p>,
        },
        {
            text: <p className='ps-4'>• Daños derivados de un mal uso o funcionamiento del TAG.</p>,
        },
        {
            text: <p><span className='font-bold'>8.1</span> Usted acepta expresamente que el uso del sitio es bajo su propio riesgo y responsabilidad. El contenido del sitio se proporciona “tal cual” como se encuentra al momento de su acceso y sin garantías de ningún tipo, ya sea de carácter expreso o implícito.</p>,
        },
        {
            text: <p><span className='font-bold'>8.2</span> PATATAG no será responsable de ningún tipo de daño, incluyendo, pero no limitado a daños emergentes, lucro cesante, pérdida de chance, pérdida de datos o cualquier otro daño material o moral que pudiera producirse o daño punitivo; inclusive en el supuesto en que se hubiere advertido al sitio oportunamente de la posibilidad de tales daños.</p>,
        },
        {
            text: <p><span className='font-bold'>8.3 TOPE.</span> Siendo la disponibilización de códigos QR, un servicio gratuito, PATATAG no asume responsabilidad alguna por el uso del sitio.</p>,
        },
        {
            text: <p><span className='font-bold'>8.4 FUNCIONALIDAD DEL SITIO.</span> PATATAG no garantiza que las funciones disponibles en los contenidos serán libres de errores, ni tampoco que el sitio o cualquier sitio vinculado al mismo, serán libres de virus u otro efecto nocivo; tampoco garantiza que el sitio será accesible en todo momento, ni tampoco que los defectos serán corregidos. El Contenido del Sitio puede contener imprecisiones técnicas, errores tipográficos o de otra índole, la utilización es bajo su propio riesgo y diligencia.</p>,
        },
        {
            text: <p><span className='font-bold'>8.5 MODIFICACIONES.</span> PATATAG es una plataforma en mejora constante, por lo cual, el contenido del sitio o el servicio de PATATAG puede cambiar, ser suspendido o discontinuado en cualquier aspecto y en cualquier momento, incluyendo su acceso al sitio y la disponibilidad de cualquier característica, base de datos o contenido. PATATAG puede, además, establecer límites al uso o acceso a ciertas características, funciones o partes de la plataforma, previa notificación al usuario. Permanentemente el equipo de PATATAG incorpora actualizaciones y nuevas funcionalidades, para mejorar el rendimiento e introducir características nuevas. En caso de que estos cambios afecten el funcionamiento de la plataforma de manera significativa y no haya una solución razonable para algún usuario que pueda verse afectado, el usuario podrá notificar a PATATAG a la casilla de correo electrónico <a href={`mailto:${COMPANY_EMAIL}`}>{COMPANY_EMAIL}</a> que desea cancelar su registro. Si PATATAG no puede eliminar los cambios y restaurar la funcionalidad a la plataforma dentro de los treinta (30) días de recibir la notificación del usuario, el usuario podrá, como único remedio, cancelar su registro en PATATAG mediante notificación por escrito.</p>,
        },
        {
            text: <p><span className='font-bold'>8.6 INDEMNIDAD.</span> Al acceder al sitio, usted se compromete a indemnizar y defender a PATATAG, sus administradores, socios y empleados, ante cualquier reclamo por daños, costos y/o gastos de cualquier tipo o naturaleza –incluyendo pero no limitado a honorarios razonables de abogados y costas- que resulten del uso que Usted hiciera del sitio o respecto a nuestros servicios y materiales disponibles.</p>,
        },
        {
            text: <p><span className='font-bold'>9. PLAZO. TERMINACIÓN</span></p>,
        },
        {
            text: <p><span className='font-bold'>9.1</span> El plazo de utilización comienza desde el momento que se crea la cuenta del usuario. A partir de la fecha de alta, la utilización será indeterminada, salvo que PATATAG o el usuario, notifique a la otra parte por medio fehaciente su voluntad de discontinuar con los servicios con una anticipación no menor a treinta (30) días de la fecha de vencimiento. </p>,
        },
        {
            text: <p><span className='font-bold'>9.2 TERMINACIÓN.</span> PATATAG o el usuario podrán dar por terminada la suscripción sin derecho a indemnización de ninguna naturaleza, ni a reintegro del costo de suscripción abonado por adelantado (en caso de suscripción paga por parte del usuario). Asimismo, el contrato, podrá ser resuelto por cualquiera de las partes en caso de incumplimiento en sus obligaciones principales y siempre que la parte incumplidora no subsane su incumplimiento –en caso de ser posible- dentro de los quince (15) días desde que fuera notificado de la falta, debiendo responder por los daños y perjuicios que su incumplimiento haya generado.</p>,
        },
        {
            text: <p><span className='font-bold'>9.3 EFECTOS DE LA TERMINACIÓN.</span> Ante la baja del registro por cualquier causa, todos los derechos otorgados al usuario respecto del acceso, uso y publicación dentro de la plataforma caerán automáticamente. La terminación no libera al usuario de los pagos que pudieran estar pendientes, de las sumas devengadas como consecuencia de la utilización de la plataforma mientras se encontraba vigente, en caso de corresponder.</p>,
        },
        {
            text: <p><span className='font-bold'>10. PUBLICIDAD.</span> PATATAG puede mencionar a los dueños de mascotas y a sus mascotas, como usuarios de PATATAG y utilizar su nombre y/o fotos y/o videos en cualquier medio, con fines promocionales de la plataforma y/o para encontrar a la mascota en caso de extravío, salvo que expresamente solicite no ser incluido o, en caso de haber sido ya incorporado, solicite a PATATAG que cese dicho uso en cualquier momento mediante notificación por medio fehaciente. </p>,
        },
        {
            text: <p><span className='font-bold'>11. NOTIFICACIONES.</span> Los usuarios de PATATAG otorgan plena validez a las notificaciones que se envíen y/o reciban a través del sitio y/o por correo electrónico en la dirección comunicada, debiendo mantenerla actualizada.</p>,
        },
        {
            text: <p><span className='font-bold'>12. LEY APLICABLE Y JURISDICCIÓN.</span> Estos Términos y Condiciones y el uso del sitio Web se rigen por las leyes de la República Argentina. Ante cualquier controversia los usuarios aceptan la jurisdicción de los Tribunales Ordinarios de la ciudad de Rosario, provincia de Santa Fe, República Argentina, renunciando de este modo a cualquier otro fuero o jurisdicción que pudiera corresponder.</p>,
        },
        {
            text: <p><span className='font-bold'>13. EL USO DE ESTE SITIO WEB NO ESTÁ AUTORIZADO EN AQUELLAS JURISDICCIONES EN QUE NO SE SATISFAGAN TODAS LAS PROVISIONES DE LOS PRESENTES TÉRMINOS Y CONDICIONES.</span></p>,
        },

    ];
    return (
        <Modal
            backdrop='opaque'
            isOpen={disclosure.isOpen}
            onOpenChange={disclosure.onOpenChange}
            placement='center'
            size="2xl"
            scrollBehavior="inside"
            classNames={{
                backdrop: 'bg-gradient-to-t from-zinc-900 to-zinc-900/10 backdrop-opacity-20',
                base: 'max-h-[90vh]',
                body: 'px-4 sm:px-6',
                header: 'px-4 sm:px-6',
                footer: 'px-4 sm:px-6'
            }}
        >
            <ModalContent>
                {(onClose) => (
                    <>
                        <ModalHeader className='modal-header flex justify-center items-center text-center relative'>
                            <h3 className='uppercase'>Términos y Condiciones</h3>
                        </ModalHeader>

                        <ModalBody>
                            <div className='text-justify space-y-4 text-sm'>
                                {paragraphs.map((paragraph, index) => (
                                    <p key={index}>{paragraph.text}</p>
                                ))}
                            </div>
                        </ModalBody>

                        <ModalFooter className='flex flex-col gap-2 pt-0'>
                            <Button
                                color='default'
                                className='w-full uppercase shadow-lg mt-1 mx-auto'
                                radius='sm'
                                onPress={onClose}
                                style={{ maxWidth: '300px' }}
                            >
                                Cerrar
                            </Button>
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    );
}