import React from 'react';

import Star from '../Icons/Star.jsx';
import StarFilled from '../Icons/StarFilled.jsx';

import './Review.css';

interface ReviewProps {
    review: {
        name: string;
        petName: string;
        petPhoto: string;
        rating: number;
        review: string[];
    };
    index: number;
}

export const Review: React.FC<ReviewProps> = ({ review, index }) => {
    return (
        <div className='review flex flex-col justify-start items-center bg-primary-light text-white shadow-lg relative p-4'>
            <div className='stars flex mb-2'>
                {[1, 2, 3, 4, 5].map((star, i) => {
                    return review.rating >= star ? <StarFilled key={i} className='mx-1' /> : <Star key={i} className='mx-1' />;
                })}
            </div>

            <div className='name-pet flex justify-center items-center mb-2'>
                <span>{review.name} y {review.petName}</span>
            </div>

            <div className='body text-center'>
                {review.review.map((line, i) => <p key={i}>{line}</p>)}
            </div>
            
            <div className={`image shadow-lg review-${index + 1}`}>
                <img src={review.petPhoto} alt={review.petName} />
            </div>
        </div>
    );
}
